<template>
   <div>
        <div class="ruleForm">
            <div style="font-size:18px;font-weight:bold;color:#292929;">指接板</div>
            <el-form :model="ruleForm" :rules="rules" ref="formCheck2" class="login-form">
                <el-form-item style="margin-bottom:10px" prop="woodKind.id" label="您备料的主要木材种类">
                    <!-- <el-select v-model="ruleForm.woodKindId" style="width:100%;" placeholder="请选择">
                        <el-option v-for="item in series" :key="item.set_no" :label="item.name" :value="item.set_no">
                        </el-option>
                    </el-select> -->
                    <el-input style="opacity: 0;" placeholder="请选择" v-model="ruleForm.woodKind.id"></el-input>
                    <div @click="showPop()" class="selectInput flex">
                        <div class="flex-1">
                            <div v-if="ruleForm.woodKind.name">{{ruleForm.woodKind.name}}</div>
                            <div v-else style="color:#ccc">请选择</div>
                        </div>
                        <div>
                            <i class="iconfont icon-youjiantou"></i>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item style="margin-bottom:20px" prop="count" label="每月总方数">
                    <div style="display:inline-block;width:calc(100% - 50px);margin-right:10px">
                        <el-input placeholder="请填写每月总方数" v-model="ruleForm.count" type="number"></el-input>
                    </div>
                    <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                </el-form-item>
                <div class="wrap">
                    <div class="wrap-title">板材规格和使用详情</div>
                    <div class="wrap-tab">
                        <div class="tab_menu flex-1">
                            <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="wrap-list">
                        <el-collapse v-if="tabIndex==0" accordion v-model="activeNames" @change="handleChange">
                            <el-collapse-item name="1">
                                 <template slot="title">
                                    14cm
                                    <div v-if="ruleForm.aa.cm14.count && ruleForm.aa.cm14.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.aa.cm14.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.aa.cm14.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="2">
                                <template slot="title">
                                    16cm
                                    <div v-if="ruleForm.aa.cm16.count && ruleForm.aa.cm16.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.aa.cm16.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.aa.cm16.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="3">
                                <template slot="title">
                                    18cm
                                    <div v-if="ruleForm.aa.cm18.count && ruleForm.aa.cm18.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.aa.cm18.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.aa.cm18.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="4">
                                <template slot="title">
                                    20cm
                                    <div v-if="ruleForm.aa.cm20.count && ruleForm.aa.cm20.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.aa.cm20.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.aa.cm20.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="5">
                                <template slot="title">
                                    22cm
                                    <div v-if="ruleForm.aa.cm22.count && ruleForm.aa.cm22.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.aa.cm22.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.aa.cm22.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="6">
                                <template slot="title">
                                    24cm
                                    <div v-if="ruleForm.aa.cm24.count && ruleForm.aa.cm24.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.aa.cm24.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.aa.cm24.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item style="color:#5074EE" name="7">
                                <template slot="title">
                                    其他规格
                                    <div v-if="ruleForm.aa.other.var && ruleForm.aa.other.count && ruleForm.aa.other.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="规格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写规格" v-model="ruleForm.aa.other.var"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">cm</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.aa.other.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.aa.other.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        <el-collapse v-if="tabIndex==1" accordion v-model="activeNames" @change="handleChange">
                            <el-collapse-item name="1">
                                 <template slot="title">
                                    14cm
                                    <div v-if="ruleForm.ab.cm14.count && ruleForm.ab.cm14.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.ab.cm14.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.ab.cm14.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="2">
                                <template slot="title">
                                    16cm
                                    <div v-if="ruleForm.ab.cm16.count && ruleForm.ab.cm16.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.ab.cm16.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.ab.cm16.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="3">
                                <template slot="title">
                                    18cm
                                    <div v-if="ruleForm.ab.cm18.count && ruleForm.ab.cm18.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.ab.cm18.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.ab.cm18.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="4">
                                <template slot="title">
                                    20cm
                                    <div v-if="ruleForm.ab.cm20.count && ruleForm.ab.cm20.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.ab.cm20.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.ab.cm20.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="5">
                                <template slot="title">
                                    22cm
                                    <div v-if="ruleForm.ab.cm22.count && ruleForm.ab.cm22.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.ab.cm22.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.ab.cm22.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="6">
                                <template slot="title">
                                    24cm
                                    <div v-if="ruleForm.ab.cm24.count && ruleForm.ab.cm24.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.ab.cm24.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.ab.cm24.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item style="color:#5074EE" name="7">
                                <template slot="title">
                                    其他规格
                                    <div v-if="ruleForm.ab.other.var && ruleForm.ab.other.count && ruleForm.ab.other.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="规格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写规格" v-model="ruleForm.ab.other.var"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">cm</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.ab.other.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.ab.other.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        <el-collapse v-if="tabIndex==2" accordion v-model="activeNames" @change="handleChange">
                            <el-collapse-item name="1">
                                 <template slot="title">
                                    14cm
                                    <div v-if="ruleForm.bj.cm14.count && ruleForm.bj.cm14.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.bj.cm14.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.bj.cm14.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="2">
                                <template slot="title">
                                    16cm
                                    <div v-if="ruleForm.bj.cm16.count && ruleForm.bj.cm16.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.bj.cm16.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.bj.cm16.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="3">
                                <template slot="title">
                                    18cm
                                    <div v-if="ruleForm.bj.cm18.count && ruleForm.bj.cm18.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.bj.cm18.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.bj.cm18.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="4">
                                <template slot="title">
                                    20cm
                                    <div v-if="ruleForm.bj.cm20.count && ruleForm.bj.cm20.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.bj.cm20.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.bj.cm20.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="5">
                                <template slot="title">
                                    22cm
                                    <div v-if="ruleForm.bj.cm22.count && ruleForm.bj.cm22.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.bj.cm22.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.bj.cm22.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="6">
                                <template slot="title">
                                    24cm
                                    <div v-if="ruleForm.bj.cm24.count && ruleForm.bj.cm24.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.bj.cm24.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.bj.cm24.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item style="color:#5074EE" name="7">
                                <template slot="title">
                                    其他规格
                                    <div v-if="ruleForm.bj.other.var && ruleForm.bj.other.count && ruleForm.bj.other.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="规格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写规格" v-model="ruleForm.bj.other.var"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">cm</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.bj.other.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.bj.other.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        <el-collapse v-if="tabIndex==3" accordion v-model="activeNames" @change="handleChange">
                            <el-collapse-item name="1">
                                 <template slot="title">
                                    14cm
                                    <div v-if="ruleForm.cb.cm14.count && ruleForm.cb.cm14.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.cb.cm14.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.cb.cm14.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="2">
                                <template slot="title">
                                    16cm
                                    <div v-if="ruleForm.cb.cm16.count && ruleForm.cb.cm16.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.cb.cm16.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.cb.cm16.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="3">
                                <template slot="title">
                                    18cm
                                    <div v-if="ruleForm.cb.cm18.count && ruleForm.cb.cm18.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.cb.cm18.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.cb.cm18.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="4">
                                <template slot="title">
                                    20cm
                                    <div v-if="ruleForm.cb.cm20.count && ruleForm.cb.cm20.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.cb.cm20.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.cb.cm20.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="5">
                                <template slot="title">
                                    22cm
                                    <div v-if="ruleForm.cb.cm22.count && ruleForm.cb.cm22.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.cb.cm22.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.cb.cm22.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item name="6">
                                <template slot="title">
                                    24cm
                                    <div v-if="ruleForm.cb.cm24.count && ruleForm.cb.cm24.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.cb.cm24.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.cb.cm24.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item style="color:#5074EE" name="7">
                                <template slot="title">
                                    其他规格
                                    <div v-if="ruleForm.cb.other.var && ruleForm.cb.other.count && ruleForm.cb.other.price"
                                        style="position:absolute;right:40px;color:#B3B3B3;">已填写</div>
                                </template>
                                <div class="wrap-item-content">
                                    <el-form-item style="margin-bottom:10px" label="规格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写规格" v-model="ruleForm.cb.other.var"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">cm</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:10px" label="数量">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写数量" v-model="ruleForm.cb.other.count" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom:0px" label="价格">
                                        <div style="display:inline-block;width:calc(100% - 90px);margin-right:10px">
                                            <el-input placeholder="请填写价格" v-model="ruleForm.cb.other.price" type="number"></el-input>
                                        </div>
                                        <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                    </el-form-item>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </el-form>
            <ItemSelect :popFlag="popFlag" @selectItem="selectItem" @hidePop="popFlag=false"></ItemSelect>
        </div>

   </div>
</template>
<script>
    import _ from 'lodash';
    import { STATIC_URL_PRE } from '@/config';
    import ItemSelect from './ItemSelect.vue';
    export default {
        components: {
            ItemSelect
        },
        model: {
            prop: "zjb",
            event: "change"
        },
        props:['zjb'],
        data() {
            return {
                staticUrl:STATIC_URL_PRE,
                tabList:[
                    {name:"AA板"},
                    {name:"AB板"},
                    {name:"白节板"},
                    {name:"C板"},
                ],
                tabIndex:0,
                rules: {
                    [`woodKind.id`]: [
                        { required: true, message: '请选择备料的主要木材种类', trigger: 'blur' }
                    ],
                    count: [
                        { required: true, message: '请填写每月总方数', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    woodKind:{
                        id:null,
                        name:null,
                    },
                    count:null,
                    aa:{
                        cm14:{
                            count:null,
                            price:null,
                        },
                        cm16:{
                            count:null,
                            price:null,
                        },
                        cm18:{
                            count:null,
                            price:null,
                        },
                        cm20:{
                            count:null,
                            price:null,
                        },
                        cm22:{
                            count:null,
                            price:null,
                        },
                        cm24:{
                            count:null,
                            price:null,
                        },
                        other:{
                            var:null,
                            count:null,
                            price:null,
                        },
                    },
                    ab:{
                        cm14:{
                            count:null,
                            price:null,
                        },
                        cm16:{
                            count:null,
                            price:null,
                        },
                        cm18:{
                            count:null,
                            price:null,
                        },
                        cm20:{
                            count:null,
                            price:null,
                        },
                        cm22:{
                            count:null,
                            price:null,
                        },
                        cm24:{
                            count:null,
                            price:null,
                        },
                        other:{
                            var:null,
                            count:null,
                            price:null,
                        },
                    },
                    bj:{
                        cm14:{
                            count:null,
                            price:null,
                        },
                        cm16:{
                            count:null,
                            price:null,
                        },
                        cm18:{
                            count:null,
                            price:null,
                        },
                        cm20:{
                            count:null,
                            price:null,
                        },
                        cm22:{
                            count:null,
                            price:null,
                        },
                        cm24:{
                            count:null,
                            price:null,
                        },
                        other:{
                            var:null,
                            count:null,
                            price:null,
                        },
                    },
                    cb:{
                        cm14:{
                            count:null,
                            price:null,
                        },
                        cm16:{
                            count:null,
                            price:null,
                        },
                        cm18:{
                            count:null,
                            price:null,
                        },
                        cm20:{
                            count:null,
                            price:null,
                        },
                        cm22:{
                            count:null,
                            price:null,
                        },
                        cm24:{
                            count:null,
                            price:null,
                        },
                        other:{
                            var:null,
                            count:null,
                            price:null,
                        },
                    },
                },
                series:[],
                activeNames:['1'],
                popFlag:false,
            }
        },
        watch:{
            ruleForm:{
                handler() {
                    this.onChange();
                },
                deep: true
            },
        },
        async created() {
            if (this.zjb) {
                this.ruleForm = this.zjb;
            }
        },
        methods: {
            onChange() {
                this.$emit('change', this.ruleForm);
            },
            showPop() {
                this.popFlag = true;
            },
            selectItem(item) {
                this.ruleForm.woodKind=item;
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            handleChange(val) {
                // console.log(val);
            },
            submitForm2() {
                // this.submit();
                // this.$router.push("/mobile/survey3");
                this.$refs['formCheck2'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                let _item = _.clone(this.ruleForm);
                let flag = false;
                let arr = [_item.aa,_item.ab,_item.bj,_item.cb];
                arr.forEach((e)=>{
                    let aa = Object.entries(e);
                    // console.log(aa);
                    aa.forEach((n)=>{
                        if (n[0]=="other") {
                            if (n[1].count && n[1].price && n[1].var) {
                                flag=true;
                            }
                        } else if (n[1].count && n[1].price) {
                            flag=true;
                        }
                    })
                })
                if (flag) {
                    this.$emit("step",3);
                } else {
                    this.$message.error('请至少填写一个规格');
                }
                console.log(arr);
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped lang="less">
.ruleForm {
    width: 94%;
    margin: 0 auto;
    .selectInput {
        position: absolute;
        left: 0;
        top: 40px;
        font-size: 14px;
        width: 100%;
        background-color: #FFF;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        padding: 0 15px;
        >div{
            height: 38px;
            line-height: 38px;
            i {
                display: inline-block;
                font-size: 16px;
                transform:rotate(90deg);
                -webkit-transform:rotate(90deg);
                position: relative;
                top: 1px;
            }
        }
    }
}
.wrap {
    .tab_menu {
        width: 100%;
        padding: 8px 0;
        .menu_item{
            display: inline-block;
            vertical-align: top;
            width: 22%;
            height: 34px;
            line-height: 32px;
            text-align: center;
            background:rgba(80,116,238,0.04);
            border-radius:4px;
            box-sizing: border-box;
            border:1px solid rgba(80,116,238,1);
            margin-right:4%;
            cursor: pointer;
            font-size: 12px;
            color: #5074EE;
        }
        .menu_item:last-child {
            margin-right: 0;
        }
        .menu_item.cur{
            color: #ffffff;
            background: #5074EE;
        }
    }
    .wrap-list {
        padding: 10px;
        background: #ffffff;
        border: 1px solid #E6E6E6;
        .wrap-item-content {
            width: 100%;
            padding: 10px;
            border: 1px solid #EBEEF5;
        }
    }
}
</style>
<style scoped>
    .wrap-list >>> .el-collapse-item__content {
        padding-bottom: 10px;
    }
    div>>> .el-collapse-item__header {
        color: #5074EE;
        position: relative;
    }
    div>>> .el-collapse-item__header i {
        color: #4D4D4D;
    }
</style>