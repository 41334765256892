<template>
   <div class="survey mobile">
        <!-- <div class="survey-top">
            <img :src="staticUrl+'design/shoujiduan/pre.jpg'">
            <div>应收账款用于备料融资，减少资金占用</div>
        </div> -->
        <div class="ruleForm">
            <el-form :model="ruleForm" :rules="rules" ref="formCheck1" class="login-form">
                <el-form-item style="margin-bottom:10px" prop="effect" label="疫情对企业的影响有哪些（多选）">
                    <el-checkbox-group v-model="ruleForm.effect">
                        <el-checkbox label="1">招工困难</el-checkbox>
                        <el-checkbox label="2">同比营销额下降</el-checkbox>
                        <el-checkbox label="3">市场消费需求低</el-checkbox>
                        <el-checkbox label="4">备料、成品仓库积压</el-checkbox>
                        <el-checkbox label="5">资金周转困难</el-checkbox>
                        <el-checkbox label="6">无影响</el-checkbox>
                        <el-checkbox label="7">其他</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item v-if="this.ruleForm.effect.indexOf('7')>-1" label="疫情对企业有哪些其他影响— —补充" prop="effect_other">
                    <el-input placeholder="请补充疫情对企业有哪些其他影响" v-model="ruleForm.effect_other"></el-input>
                </el-form-item>
                <el-form-item prop="online_cs" label="目前线上化运营，有哪些尝试">
                    <el-input placeholder="请填写" v-model="ruleForm.online_cs"></el-input>
                </el-form-item>
                <el-form-item prop="monthSaleAr" label="现阶段每月营销额">
                    <div style="display:inline-block;width:calc(100% - 65px);margin-right:10px">
                        <el-input placeholder="请填写现阶段每月营销额" v-model.number="ruleForm.monthSaleAr" type="number"></el-input>
                    </div>
                    <div style="display:inline-block;font-size:12px;color:#808080;">万元/月</div>
                </el-form-item>
                <el-form-item prop="monthBlAmt" label="每月备料预算流转资金">
                    <div style="display:inline-block;width:calc(100% - 65px);margin-right:10px">
                        <el-input placeholder="请填写每月备料预算流转资金" v-model.number="ruleForm.monthBlAmt" type="number"></el-input>
                    </div>
                    <div style="display:inline-block;font-size:12px;color:#808080;">万元/月</div>
                </el-form-item>
                <el-form-item label="是否需要金融解决方案支持">
                    <div>
                        <el-switch v-model="ruleForm.needJr"
                            active-text="是" inactive-text="否">
                        </el-switch>
                    </div>
                </el-form-item>
                <el-form-item style="margin-bottom:0;" label="备料中心正式投产后，您是否愿意在备料中心进行备料？">
                    <div>
                        <el-switch v-model="ruleForm.needBl" :validate-event="true"
                            active-text="是" :active-value="true" inactive-text="否">
                        </el-switch>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <!-- <div class="survey-button flex flex-align-center flex-pack-center">
            <el-button @click="submitForm()" size="small" type="primary">下一步1/3</el-button>
        </div> -->
        
   </div>
</template>
<script>
    import { STATIC_URL_PRE } from '@/config';
    export default {
        components: {},
        model: {
            prop: "detail",
            event: "change"
        },
        props:['detail'],
        data() {
            return {
                staticUrl:STATIC_URL_PRE,
                rules: {
                    effect: [
                        { required: true, message: '请选择疫情对企业的影响', trigger: 'blur' }
                    ],
                    effect_other: [
                        { required: true, message: '请补充疫情对企业有哪些其他影响', trigger: 'blur' }
                    ],
                    online_cs: [
                        { required: true, message: '请填写目前线上化运营，有哪些尝试', trigger: 'blur' }
                    ],
                    monthSaleAr: [
                        { required: true, message: '请填写现阶段每月营销额', trigger: 'blur' }
                    ],
                    monthBlAmt: [
                        { required: true, message: '请填写每月备料预算流转资金', trigger: 'blur' }
                    ],
                    needJr: [
                        { required: true, message: '是否需要金融解决方案支持', trigger: 'blur' }
                    ],
                    needBl: [
                        { required: true, message: '是否愿意在备料中心进行备料？', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    effect:[],
                    effect_other:null,
                    online_cs:null,
                    monthSaleAr:null,
                    monthBlAmt:null,
                    needJr:false,
                    needBl:false,
                    zjb:null,
                    ztl:null,
                },
            }
        },
        watch:{
            ruleForm:{
                handler() {
                    this.onChange();
                },
                deep: true
            }
        },
        async created() {
            if (this.detail) {
                this.ruleForm = this.detail;
            }
            // if (this.ruleForm.effect && this.ruleForm.effect.indexOf('7')>-1) {
            //     console.log(6666);
            // }
        },
        methods: {
            onChange() {
                this.$emit('change', this.ruleForm);
            },
            submitForm1() {
                this.$refs['formCheck1'].validate((valid) => {
                    if (valid) {
                        this.$emit("step",2);
                    }
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .ruleForm>>> .el-checkbox {
        display: block;
    }
    .ruleForm>>> .el-form-item__label {
        float: none;
    }
</style>
<style scoped lang="less">
.survey-top {
    width: 94%;
    margin: 10px auto 10px;
    img {
        width: 100%;
    }
    >div {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #F8F9FF;
        border: 1px solid #DBE1F6;
        font-size: 16px;
        color: #A9B6E1;
        margin-top: 10px;
    }
}
.ruleForm {
    width: 94%;
    margin: 0 auto;
    border: 1px solid #E6E6E6;
    padding: 0 10px;
}
.survey-button {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    box-shadow:0px -1px 0px 0px rgba(230,230,230,1);
    position: absolute;
    left:0;
    bottom: 0;
}
</style>