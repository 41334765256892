<template>
   <div>
        <div :class="{'active':popFlag}" class="pop_bottom">
            <div class="list">
                <div class="list-title">您备料的木材种类</div>
                <div class="list-content">
                    <div class="select_list flex">
                        <div class="select_item" v-for="(item,idx) in materials" @click="tab(item,idx)" :class="{'active':selIndex===idx}" :key="idx">
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="survey-button flex flex-align-center flex-pack-center">
                <el-button @click="hide()" style="margin-right:20px" size="small" type="primary" plain>取消</el-button>
                <el-button @click="submitForm()" size="small" type="primary">确认</el-button>
            </div>
        </div>

   </div>
</template>
<script>
    import _ from 'lodash';
    import { STATIC_URL_PRE } from '@/config';
    import { getMaterials } from '@/service/item';
    export default {
        props:['popFlag'],
        data() {
            return {
                materials:[],
                selIndex:null,
                selItem:null,
            }
        },
        async created() {
            this.materials = await getMaterials();
        },
        methods: {
            tab(item,index) {
                this.selIndex = index;
                this.selItem = {
                    id:item.id,
                    name:item.name,
                };
            },
            hide() {
                this.$emit("hidePop");
            },
            submitForm() {
                if (this.selIndex != null) {
                    this.$emit("selectItem",this.selItem)
                    this.hide();
                } else {
                    this.$message.error("请选择");
                }
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped lang="less">
.pop_bottom {
    width: 100%;
    height: 100%;
    background:linear-gradient(180deg,rgba(253,253,253,1) 0%,rgba(250,250,250,1) 100%);
    position: absolute;
    bottom: -100%;
    left: 0;
    z-index: 999;
    transition: linear 0.15s;
    -webkit-transition: linear 0.15s;
    .survey-button {
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        box-shadow:0px -1px 0px 0px rgba(230,230,230,1);
        position: absolute;
        left:0;
        bottom: 0;
    }
}
.list {
    width: 96%;
    margin: 0 auto 0;
    height: calc(100% - 50px);
    .list-title {
        font-size: 18px;
        color: #292929;
        font-weight:400;
        padding: 10px 0 5px 7px;
    }
    .list-content {
        width: 100%;
        height: calc(100% - 45px);
        overflow: auto;
        .select_list {
            width: 100%;
            .select_item {
                padding: 0 15px;
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                color: #5074EE;
                cursor: pointer;
                border: 1px solid #5074EE;
                border-radius: 15px;
                margin: 5px 7px;
                &.active {
                    background: #5074EE;
                    color: #fff;
                }
            }
        }
    }
}
.pop_bottom.active {
    bottom: 0;
}
</style>